import React from 'react'

import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'

import { fetchServer } from '../api/fetchServer'
import { container } from '../container'
import { analytics } from '../services/analytics'
import { initialiseWelcomePage } from './Welcome/utils/initialiseWelcomePage'

class DeviceRegistration extends React.Component {
	state = {
		deviceRegistrationError: '',
		deviceRegistrationErrorStatusCode: 0
	}

	constructor(props) {
		super(props)
		/*
	if(props.error && props.error.response && props.error.response.json){
	  props.error.response.json().then((resp)=>{
		console.log('DeviceRegistration constructor', props.authenticated, resp)
	  })
	}
	 */
	}

	generateToken = () => {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		)
	}

	async registerDevice() {
		console.log('registering device')
		const deviceSerial = container.getDeviceSerial()
		let deviceToken = container.getDeviceToken(true)
		if (deviceToken) {
			console.error(
				'tried calling registerDevice with existing deviceToken ' +
					deviceToken
			)
			return
		}
		let unverifiedDeviceToken = container.getDeviceToken(false)

		if (unverifiedDeviceToken) {
			// see if the token actually works
			console.log(
				'unverified token found while registering, trying verify...',
				unverifiedDeviceToken
			)
			let response
			try {
				let url = new URL(`/merchantapi/v1/user`, window.location.href)
					.href
				const res = await fetch(url, {
					method: 'GET',
					headers: {
						'device-external-token': unverifiedDeviceToken
					}
				})
				response = await res.json()
				/*response = await fetchServer(`/v1/user`, 'GET', {}, {
		  deviceToken: unverifiedDeviceToken
		})*/
			} catch (e) {
				console.warn(
					'unverifiedDeviceToken validation failed',
					e.response
				)
				//console.log(e)
			}
			if (response && response.code && response.code === 1) {
				console.log(
					'previously unverified device token  verified, writing to disk...'
				)
				// save token as verified
				try {
					await container.setDeviceToken(unverifiedDeviceToken, true)
					this.setState({ deviceRegistrationError: '' })
					console.log('Token verified and successfully saved to disk')
				} catch (e) {
					console.log('Failed writing verified token to disk')
					this.setState({
						deviceRegistrationError: 'File write error.'
					})
				}
				window.location.reload()
				return
			} else {
				console.log(
					'could not verify device token',
					unverifiedDeviceToken
				)
				// clear non-working unverified device token
				try {
					await await container.setDeviceToken('', false)
					this.setState({ deviceRegistrationError: '' })
					console.log('verified device token cleared')
				} catch (e) {
					console.log('failed clearing verified device token')
					this.setState({
						deviceRegistrationError: 'File write error.'
					})
				}
				unverifiedDeviceToken = ''
			}
		}

		Sentry.addBreadcrumb({
			message: 'Registering device serial number',
			data: { deviceSerial },
			category: 'deviceAuthentication'
		})

		if (!unverifiedDeviceToken) {
			unverifiedDeviceToken = this.generateToken()
			console.log(
				'no previously saved unverified device token found. Generating a new one',
				unverifiedDeviceToken
			)
			try {
				await container.setDeviceToken(unverifiedDeviceToken, false)
			} catch (e) {
				console.log('could not save unverified device token', e)
				return
			}
		}

		console.log(
			`registering device ${deviceSerial} with token ${unverifiedDeviceToken}`
		)
		let res
		try {
			const url = `/v1/devices/authenticate`
			res = await fetchServer(url, 'POST', {
				serial: deviceSerial,
				deviceExternalToken: unverifiedDeviceToken
			})
		} catch (error) {
			const response = await error.response.json()
			console.log('device registration failed', response)
			this.setState({
				deviceRegistrationError: response.message,
				deviceRegistrationErrorStatusCode: response.code
			})
			Sentry.addBreadcrumb({
				message: 'Failed registering device serial number',
				data: { deviceSerial, response },
				category: 'deviceAuthentication'
			})
			Sentry.captureException(error)
		}

		if (res && res.code && res.code === 1 && res.data.token) {
			const deviceToken = res.data.token
			console.log('device registered', res.code, deviceToken)
			Sentry.addBreadcrumb({
				message: 'Successfully registered device serial number',
				data: { deviceSerial, deviceToken },
				category: 'deviceAuthentication'
			})
			try {
				await container.setDeviceToken(deviceToken, true)
				initialiseWelcomePage()
			} catch (error) {
				this.setState({ deviceRegistrationError: error.message })
				console.warn('failed writing device token', error)
				Sentry.addBreadcrumb({
					message: 'Failed saving device token',
					extra: {
						...error,
						...{
							deviceSerial,
							deviceToken
						}
					}
				})
			}
			console.log('device registered', deviceToken)
			Sentry.addBreadcrumb({
				message: 'Successfully saved device serial number',
				data: { deviceSerial, deviceToken },
				category: 'deviceAuthentication'
			})
			this.setState({ deviceRegistrationError: '' })
			window.location.reload()
		}
	}

	componentDidMount() {
		const deviceSerial = container.getDeviceSerial()
		const deviceToken = container.getDeviceToken(true)

		if (deviceSerial) {
			analytics.setDeviceId(deviceSerial)
		}

		if (deviceSerial && !deviceToken) {
			this.registerDevice().then()
		}
		if (!deviceToken && !deviceSerial && !this.props.authenticated) {
			return null
		}
	}

	render() {
		const deviceSerial = container.getDeviceSerial()

		if (
			this.props.authenticated ||
			!deviceSerial ||
			(!this.state.deviceRegistrationError && !this.props.error?.message)
		) {
			return ''
		}
		/*
	const deviceSerial = container.getDeviceSerial();
	const deviceToken = container.getDeviceToken();
	return <div>DEVICE: {deviceSerial} {deviceToken}</div>
	 */
		const errorType = this.state.deviceRegistrationError
			? 'registration'
			: this.props.error.message
			? 'auth'
			: null
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '100px'
				}}>
				<div>
					<h3>
						Device SN. {deviceSerial} is not properly configured.{' '}
					</h3>
					<p style={{ fontSize: '18px', marginBottom: 16 }}>
						Please check device configuration in panel and try
						again.
					</p>

					<p>Server response:</p>
					<p style={{ fontStyle: 'italic', marginBottom: 16 }}>
						{errorType}:{' '}
						{this.state.deviceRegistrationError ||
							this.props.error.message}
					</p>
					<br />
					<br />
				</div>
			</div>
		)
	}
}

DeviceRegistration.propTypes = {
	authenticated: PropTypes.bool,
	error: PropTypes.object
}

export default DeviceRegistration
