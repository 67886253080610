import { useEffect } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { analytics } from '../../../../services/analytics'
import { MerchantPhoneNumber } from './MerchantPhoneNumber'
import { MerchantServiceRequestForm } from './MerchantServiceRequestForm'
import { MerchantServiceRequestList } from './MerchantServiceRequestList'
import { Props } from './types'

export const HelpCenterContactUs = ({ onSubmitSuccess }: Props) => {
	useEffect(() => {
		analytics.trackEvent('View Contact Us')
	}, [])

	return (
		<>
			<Typography fontSize={20} fontWeight={700} mb={1}>
				Create support ticket
			</Typography>

			<Typography>
				You can create a support ticket, which our agents will treat
				shortly.
			</Typography>
			<Typography fontWeight={600}>
				Note: you cannot create 2 tickets for the same purpose.
			</Typography>

			<Box mb={2}>
				<MerchantServiceRequestForm onSuccess={onSubmitSuccess} />
			</Box>

			<MerchantServiceRequestList />

			<Stack alignItems="center" mt={2} mb={8}>
				<Typography fontSize={18}>
					For any other inquiries call us at
				</Typography>

				<MerchantPhoneNumber />
			</Stack>
		</>
	)
}
